import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MessageContent} from '@nit-core/models/chat-notification';
import {Room} from '@nit-core/models/room';
import {RoomNamePipe} from '@nit-core/pipes/room-name.pipe';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'nit-chat-search-list',
  templateUrl: './chat-search-list.component.html',
  styleUrl: './chat-search-list.component.scss',
  standalone: true,
  imports: [CommonModule, RoomNamePipe]
})
export class ChatSearchListComponent {
  @Input() rooms: Room[];
  @Input() messages: MessageContent[];
  @Output() selectedRoom: EventEmitter<string> = new EventEmitter<string>(null);

}
