<div id="semester-filter-chats">
  <button #select class="select" (click)="openMenu($event)">
    {{selectedSemester === 1 ? 'I' : 'II'}} сем
    <img src="/assets/icons/chats/arrow-down.svg" alt="">
  </button>
  <kendo-contextmenu #target (select)="changeSemester($event.index)">
    <ng-container *ngFor="let filter of semesters">
      <kendo-menu-item
        [cssClass]="filter === selectedSemester ? 'menu-item chat-semester-filter-chats select' : ' chat-semester-filter-chats menu-item'"
        [text]="filter === 1 ? 'I семестр' : 'II семестр'" />
    </ng-container>
  </kendo-contextmenu>
</div>
