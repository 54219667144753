<div class="chat-search">
  <div class="input-wrapper">
    <img class="chat-search-icon" src="./assets/icons/chats/magnifier.svg" alt="magnifier">
    <input #searchChat class="chat-search-input" type="text" [placeholder]="isChannelsSelect ? 'Пошук каналів' : 'Пошук чатів'">
  </div>
  <button *ngIf="!isChannelsSelect && (!isSchool || isAdminOrTeacherInCurrentSchool)" #create class="create" (click)="openMenu($event, false)">
    <img src="../../../../../../assets/icons/chats/plus-icon.svg" alt="add-plus">
  </button>
  <kendo-contextmenu #targetCreate (select)="route($event.item.text)">
    <ng-container>
      <kendo-menu-item *ngIf="isAdminOrTeacherInCurrentSchool" cssClass="menu-item create-chat-group" text="Створити групу" />
      <kendo-menu-item cssClass="menu-item creat-chat-private" text="Особисте повідомлення" />
    </ng-container>
  </kendo-contextmenu>
  <button *ngIf="!isChannelsSelect" #sort class="sort" (click)="openMenu($event)">
    <img src="../../../../../../assets/icons/chats/sort.svg" alt="watering-can">
  </button>
  <kendo-contextmenu #target (select)="selectFilter($event.item.text)">
    <kendo-menu-item disabled="true" text="Показати:" />
    <ng-container *ngFor="let filter of filterList">
      <kendo-menu-item [cssClass]="filter === selectFilterTitle ? 'menu-item select-before' : 'menu-item'" [text]="filter" />
    </ng-container>
  </kendo-contextmenu>
</div>
