import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ChatType, FeathersHubService} from '@nit-core/global/domain/enums';
import {ActivatedRoute, Router} from '@angular/router';
import {ContextMenuComponent, MenuItemComponent} from '@progress/kendo-angular-menu';
import {SearchRoom} from '@nit-core/models/room';
import {catchError, debounceTime, distinctUntilChanged, EMPTY, filter, fromEvent, map, Subject, switchMap, takeUntil, tap} from 'rxjs';
import {AuthService} from '@nit-auth';
import {ChatService} from '../../services/chat.service';
import {FeathersService, UserService} from '@nit-services';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'nit-filter-chats',
  templateUrl: './filter-chats.component.html',
  styleUrl: './filter-chats.component.scss',
  standalone: true,
  imports: [CommonModule, ContextMenuComponent, MenuItemComponent]
})

export class FilterChatsComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() selectedClass: string;
  @Input() semester: number;
  @Input() schoolId: number;
  @Input() isSchool: boolean;
  @Input() isChannelsSelect: boolean;
  @ViewChild('sort') sort: ElementRef;
  @ViewChild('target') target: ContextMenuComponent;
  @ViewChild('create') create: ElementRef;
  @ViewChild('targetCreate') targetCreate: ContextMenuComponent;
  @Output() sortRooms: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchRoom: EventEmitter<SearchRoom> = new EventEmitter<SearchRoom>();
  @ViewChild('searchChat') searchChat!: ElementRef;

  filterList: string[] = ['Всі', 'Особисті повідомлення', 'Групи', 'Непрочитані'];
  selectFilterTitle: string = 'Всі';
  isUserAdminOrTeacher: boolean;
  currentUserId: string;
  childId: string;
  childActualIdClass: string;
  isChild: boolean = false;
  isChildrenList: boolean;
  isAdminOrTeacherInCurrentSchool: boolean;
  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _featherService: FeathersService,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _chatService: ChatService,
    private readonly _route: ActivatedRoute,
    private readonly _userService: UserService,
  ) {
    this.isChild = this._userService.isChild$.getValue();
    if (this.isChild) {
      this.childActualIdClass = this._userService.currentSchoolInfo$.getValue().classId;
    }
    this.isChildrenList = this._route.snapshot.data.isChildrenList;
  }

  ngOnChanges(): void {
    this.selectFilterTitle = this.filterList[0];
    if (this.searchChat) {
      this.searchChat.nativeElement.value = '';
    }
  }

  ngOnInit(): void {
    this.currentUserId = this._authService.userId;
    this.childId = this._route.snapshot.paramMap.get('userId');

    this._isUserAdminOrTeacher();
    this._initFilterList();
    this.selectFilterTitle = this.filterList[0];
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchChat.nativeElement, 'keyup')
      .pipe(
        map((e: any) => {
          return e?.target?.value;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        tap(v => !v ? this.searchRoom.emit(null) : ''),
        filter(v => v.trim()),
        switchMap(v => this.isChannelsSelect ? this._featherService.find(FeathersHubService.Channels, {'name': {$search: v}}) : this._featherService.find(FeathersHubService.Rooms, {school: this.schoolId, 'class._id': this.selectedClass, semester: {$in: [0, this.semester]}, 'name': {$search: v}})
          .pipe(
            catchError(() => {
              this.searchRoom.emit(null);

              return EMPTY;
            }
            )
          )),
      )
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) =>{
        this.searchRoom.emit({
          rooms: res.data,
          messages: []
        });
      });
  }

  route(event: string): void {
    if (this.childId) {
      this._router.navigate(['/children', this.childId, 'chat', 'create'], {
        queryParams: {
          semester: this.semester,
          class: this.selectedClass,
          type: event === 'Створити групу' ? ChatType.Direct : ChatType.Private,
          school: this.schoolId,
          childId: this.childId,
        }});

      return;
    }

    this._router.navigate(['/chat', 'create'], {
      queryParams: {
        semester: this.semester,
        class: this.selectedClass,
        type: event === 'Створити групу' ? ChatType.Direct : ChatType.Private,
        school: this.schoolId
      }});
  }

  selectFilter(event: string): void {
    this.selectFilterTitle = event;
    this.sortRooms.emit(this.selectFilterTitle);
  }

  openMenu(event: any, isSort: boolean = true) {
    this[isSort ? 'target' : 'targetCreate'].show(isSort ? this.sort : this.create);
    this[isSort ? 'target' : 'targetCreate'].show({left: event.clientX, top: event.clientY});
  }

  openSortMenu(): void {
    this.target.show(this.sort);
    this.target.show({left: 280, top: 56});
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  private _isUserAdminOrTeacher(): void {
    this.isUserAdminOrTeacher = this._chatService.isUserAdminOrTeacher(this.currentUserId);
    if (this.isUserAdminOrTeacher) {
      this.isAdminOrTeacherInCurrentSchool = String(this.schoolId) === localStorage.getItem('schoolId');
    }
  }

  private _initFilterList(): void {
    if (!this.isUserAdminOrTeacher) {
      this.filterList = ['Всі', 'Особисті повідомлення', 'Групи'];

      return;
    }

    this.filterList = ['Всі', 'Особисті повідомлення', 'Групи', 'Непрочитані'];
  }
}
