<div class="class-selector">
  <nit-semester-filter *ngIf="!isAdminChannelsList"
                       [userId]="userId"
                       (semesterChanged)="semesterChanged($event, isChildrenList)" />

  <div *ngIf="classes?.length || isAdminChannelsList" class="class-list">
    <ng-container *ngIf="teacherOwnClasses?.length">
      <div *ngFor="let teacherOwnClass of teacherOwnClasses; let i = index"
           class="class-chat"
           [class.last-own-class]="i + 1 === teacherOwnClasses.length">
        <button [class.selected]="selectedClass === teacherOwnClass?._id"
                [class.notification]="teacherOwnClass.unread_v2 > 0"
                (click)="selectClass(teacherOwnClass._id)">
          {{teacherOwnClass.name}}
        </button>
      </div>
    </ng-container>

    <ng-container *ngFor="let classChat of classes; let index = index">
      <div *ngIf="!tutorClassesIds['_' + classChat?._id]"
           class="class-chat"
           [class.school]="classChat.isSchool">
        <button [class.selected]="selectedClass === classChat._id"
                [class.selected-school]="selectedClass === classChat._id && classChat.isSchool"
                [class.notification]="classChat.unread_v2 > 0"
                [class.school]="classChat.isSchool"
                (click)="selectClass(classChat._id)">
              <div *ngIf="classChat.isSchool; else isClassChat" class="school-chat"></div>
              <ng-template #isClassChat>
                {{ classChat.name }}
              </ng-template>
        </button>
      </div>
    </ng-container>

    <div *ngIf="isPersonWithChannels || isAdminChannelsList" class="class-chat">
      <button [class.selected]="selectedClass === 'channels'"
              (click)="selectClass('channels')">
        <div class="channels-chat"></div>
      </button>
    </div>
  </div>
</div>
