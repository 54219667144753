import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AcademicYearService} from '@nit-services';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AuthService} from '@nit-auth';
import {ActivatedRoute} from '@angular/router';
import {ContextMenuComponent, MenuItemComponent} from '@progress/kendo-angular-menu';
import {NgFor} from '@angular/common';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'nit-semester-filter',
  templateUrl: './semester-filter.component.html',
  styleUrl: './semester-filter.component.scss',
  standalone: true,
  imports: [ContextMenuComponent, NgFor, MenuItemComponent]
})
export class SemesterFilterComponent implements OnInit {
  @ViewChild('select') select: ElementRef;
  @ViewChild('target') target: ContextMenuComponent;
  @Input() userId: string;
  @Input() isAdminChannelsList: boolean;
  @Output() semesterChanged: EventEmitter<number> = new EventEmitter<number>();

  semesters: number[] = [1, 2];
  selectedSemester: number;
  schoolId: number;
  childId: string;

  constructor(private readonly _academicYearsService: AcademicYearService,
              private readonly _authService: AuthService,
              private readonly _route: ActivatedRoute) {
    this.childId = this._route.snapshot.paramMap.get('userId');
    this.schoolId = this.childId === this._authService.userId
      ? this._authService.getUserSchoolId()
      : this._authService.getUserSchoolId(this.childId);
  }

  ngOnInit(): void {
    this._getCurrentSemester(this.childId, this.schoolId);
  }

  changeSemester(selectedSemesterIndex: number): void {
    this.selectedSemester = +selectedSemesterIndex ? 2 : 1;
    this.semesterChanged.emit(this.selectedSemester);
  }
  openMenu(event: any) {
    this.target.show(this.select);
    this.target.show({left: event.clientX, top: event.clientY});
  }

  private _getCurrentSemester(userId: string, schoolId: number): void {
    if (!this.isAdminChannelsList && schoolId !== 99999) {
      this._academicYearsService.getActive(userId, schoolId).subscribe(res => {
        if (res) {
          this.selectedSemester = this._academicYearsService.getCurrentSemester(res)?.semester;
          this.semesterChanged.emit(this.selectedSemester);
        } else {
          this.semesterChanged.emit(1);
        }
      });
    }
  }
}
