import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@nit-auth';
import {FeathersService, UserService} from '@nit-services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SchoolInfo} from '@nit-models';
import {ChatsClass} from '@nit-core/models/class';
import {FeathersHubMethod, FeathersHubService} from '@nit-core/global/domain/enums';
import {SemesterFilterComponent} from './semester-filter/semester-filter.component';
import {CommonModule} from '@angular/common';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'nit-class-selector-bar',
  templateUrl: './class-selector-bar.component.html',
  styleUrl: './class-selector-bar.component.scss',
  standalone: true,
  imports: [CommonModule, SemesterFilterComponent]
})
export class ClassSelectorBarComponent implements OnInit, OnChanges {
  @Input() classes: ChatsClass[];
  @Input() userId: string;
  @Input() childActualClass: SchoolInfo;
  @Input() teacherOwnClasses: ChatsClass[];
  @Input() isAdminOrTeacher: boolean = false;
  @Input() isChildrenList: boolean;
  @Input() selectedClass: string;
  @Input() isAdminChannelsList: boolean;
  @Input() isPersonWithChannels: boolean;

  @Output() classSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedSemester: EventEmitter<number> = new EventEmitter<number>();

  schoolId: number;
  childId: string;
  isChild: boolean = false;
  semester: number;
  tutorClassesIds: {[index: string]: boolean} = {};

  constructor(public router: Router,
              private readonly _route: ActivatedRoute,
              private readonly _authService: AuthService,
              private readonly _userService: UserService,
              private readonly _feathersService: FeathersService) {
    this.isChild = this._userService.isChild$.getValue();
    this.childId = this._route.snapshot.paramMap.get('userId');
    this.schoolId = this.childId === this._authService.userId
      ? this._authService.getUserSchoolId()
      : this._authService.getUserSchoolId(this.childId);
  }

  ngOnInit(): void {
    this._route.queryParams.pipe(untilDestroyed(this)).subscribe(queryParams => {
      if (queryParams.class && !this.selectedClass) {
        this.selectClass(queryParams.class);
      }
    });

    this._listenUnreadClass();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isChild && changes.classes?.currentValue?.length) {
      this.teacherOwnClasses = changes.childActualClass.currentValue;
      this.selectClass(changes.childActualClass.currentValue[0]?._id);
    }

    if (changes.teacherOwnClasses?.currentValue?.length) {
      this.teacherOwnClasses.map(classOfTutor => classOfTutor._id).forEach(id => {
        this.tutorClassesIds['_' + id] = true;
      });
    }
  }

  selectClass(id: string): void {
    if (this.router.url.includes('create') || this.router.url.includes('saved') ) {
      this.router.navigate(this.childId ? [`/children/${this.childId}/chat`] : ['/chat']);
    }
    if (id) {
      this.selectedClass = id;
      this.classSelected.emit(id);
    }
  }

  getSchoolInfo(): void {
    this.classes = [];
    this._userService.getSchoolInfo(this.childId, this.schoolId)
      .subscribe((data) => {
        this.classes.push(...this._formatSchoolInfo([data]));

        this._feathersService.find(FeathersHubService.Classes, {semester: {$in: [0, this.semester]} , 'school': this.schoolId})
          .subscribe((res) => {
            const schoolChat = res.data.find(chat => chat.isSchool);
            if (schoolChat) {
              this.classes.push(schoolChat);
            }
          });
      });
  }

  semesterChanged(event: number, isReset: boolean = false): void {
    if (!isReset && !this.isChild) {
      this.selectClass('');
    }
    this.semester = event;
    if (this.childId) {
      this.getSchoolInfo();
    }
    this.selectedSemester.emit(event);
  }

  private _formatSchoolInfo(data: SchoolInfo[]): ChatsClass[] {
    const result = [];

    data.forEach(res => {
      result.push({
        _id: res.classId,
        name: res.className,
        hasNushRule: res.hasNushRule,
        schoolName: res.schoolName,
        teacherFullName: res.teacherFullName,
        teacherId: res.teacherId,
        teacherImg: res.teacherImg,
        teacherName: res.teacherName,
        teacherPhoneNumber: res.teacherPhoneNumber
      });
    });

    return result;
  }

  private _listenUnreadClass(): void {
    this._feathersService.listen(FeathersHubMethod.MessageReceived_v2)
      .subscribe(res => {
        const {type, targetId, unread} = res.data;
        if (type === 'class') {
          const index = this.classes.findIndex(element => element._id === targetId);
          if (index !== -1) {
            this.classes[index].unread_v2 = unread;
          }
        }
      });
  }
}
