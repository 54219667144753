<div class="room-selector">
  <div class="top">
    <nit-filter-chats [schoolId]="schoolId"
                      [semester]="semester"
                      [selectedClass]="selectedClass"
                      [isSchool]="isSchool"
                      [isChannelsSelect]="isChannelsSelect"
                      (searchRoom)="searchRoom($event)"
                      (sortRooms)="sortRooms($event)" />
    <button *ngIf="!searchData && !isChannelsSelect" [class.selected]="router.url.includes('saved')" (click)="savedChat()">
      <img src="./assets/images/chats/saved.svg" alt="Bookmark icon">
      Збережені повідомлення
    </button>

    <button *ngIf="isSchool && !searchData && isAdmin"
      [routerLink]="['/chat', 'online-lesson']"
      [class.selected]="selectedRoomId === 'onlineLesson' || router.url.includes('online-lesson')"
      (click)="selectedRoomId = 'onlineLesson'">
      <img src="../../../../../../assets/icons/chats/online-lesson.svg" alt="Bookmark icon">
      Активні онлайн уроки
    </button>
    <div class="line"></div>
  </div>

  <div *ngIf="rooms?.length && !searchData"
    class="class-chat"
    [ngStyle]="{'margin-top': (isChannelsSelect ? '15px' : '')}">
    <ng-container *ngIf="rooms.length">
      <ng-container *ngFor="let room of rooms">
        <nit-chat-list-item #chatItem
          [calls]="calls"
          [room]="room"
          [isSchool]="isSchool"
          [currentUser]="currentUser"
          [isChannelsSelect]="isChannelsSelect"
          [roomsWhereUserIsMember]="roomsWhereUserIsMember"
          [schoolId]="schoolId"
          [selectedRoomId]="selectedRoomId"
          (selectedRoom)="selectRoom($event)"
          (leaveRoom)="leaveRoom($event)" />
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="searchData" class="class-chat">
    <nit-chat-search-list [rooms]="searchData.rooms" [messages]="searchData.messages"
      (selectedRoom)="selectRoom($event)" />
  </div>
</div>
