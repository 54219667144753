import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ChatType, FeathersHubMethod, FeathersHubService} from '@nit-core/global/domain/enums';
import {ChatCall} from '@nit-core/models/chat-notification';
import {Room} from '@nit-core/models/room';
import {Profile} from '@nit-models';
import {ContextMenuComponent, MenuItemComponent} from '@progress/kendo-angular-menu';
import {Subject, merge, takeUntil} from 'rxjs';
import {getPrivateRoomName, isUserRoomOwner} from '../../utils/chat.utils';
import {TimeViewPipe} from '@nit-core/pipes/time-view.pipe';
import {DeleteRoomModalComponent} from '../delete-room-modal/delete-room-modal.component';
import {DateTransformService, FeathersService, UserService} from '@nit-services';
import {ChatService} from '../../services/chat.service';
import {CommonModule, formatDate} from '@angular/common';
import {NitTooltipDirective} from '@nit-core/directives/tooltip/nit-tooltip.directive';
import {TrimTagsPipe} from '@nit-core/pipes/trim-tags.pipe';
import {RoomNamePipe} from '@nit-core/pipes/room-name.pipe';

@Component({
  selector: 'nit-chat-list-item',
  templateUrl: './chat-list-item.component.html',
  styleUrl: './chat-list-item.component.scss',
  standalone: true,
  imports: [CommonModule, NitTooltipDirective, ContextMenuComponent, MenuItemComponent, DeleteRoomModalComponent, TimeViewPipe, TrimTagsPipe, RoomNamePipe]
})
export class ChatListItemComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @ViewChild('target') target: ContextMenuComponent;
  @ViewChild('chat') chat: ElementRef;
  @ViewChild('chatName') chatName: ElementRef;
  @ViewChild('deleteModal') deleteModal: DeleteRoomModalComponent;

  @Input() room: Room;
  @Input() schoolId: string;
  @Input() currentUser: Profile;
  @Input() selectedRoomId: string;
  @Input() calls: ChatCall[] = [];
  @Input() roomsWhereUserIsMember: Room[];
  @Input() isSchool: boolean;
  @Input() isChannelsSelect: boolean;

  @Output() selectedRoom: EventEmitter<string> = new EventEmitter<string>(null);
  @Output() leaveRoom: EventEmitter<string> = new EventEmitter<string>(null);

  isMobileDevice: boolean = false;
  isOnlineLesson: ChatCall;
  chatType: typeof ChatType = ChatType;
  roomName: string;
  lastInteractionTime: string;
  isChatNameEllipsized: boolean;
  isUserRoomOwner: boolean;
  isChild: boolean = false;
  isCurrentUserAdmin: boolean;
  isChildrenList: boolean;
  isUserRoomMember: boolean;
  middleNameSize: boolean;
  canLeaveChat: boolean;

  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _feathersService: FeathersService,
    private readonly _userService: UserService,
    private readonly _chatService: ChatService,
    public router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _dateTransformService: DateTransformService
  ) {
    this.isChild = this._userService.isChild$.getValue();
    this.isMobileDevice = window.innerWidth < 768;
    this.isChildrenList = this._route.snapshot.data.isChildrenList;
    this._listenCallEnded();
    this._subscribeOnCreate();
    this._subscribeOnRemove();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isUserRoomMember = !!this.roomsWhereUserIsMember.find(r => r._id === this.room._id);
    if (changes.room?.currentValue) {
      this.getRoomName();
      this._getLastInteractionTime();
      this.middleNameSize = !!(this.room.muted && ( this.room.pinned?._id || this.room.lastMsg?.user?._id === this.currentUser.id) || this.room.pinned?._id && this.room.lastMsg?.user?._id === this.currentUser.id);
      this.canLeaveChat = this.room.type !== this.chatType.Default && this.room.type !== this.chatType.Private && !isUserRoomOwner && !this.isChild && !this.isChildrenList && !this.isChannelsSelect;
    }
  }

  ngOnInit(): void {
    this.isCurrentUserAdmin = this._chatService.isUserAdmin(this.currentUser.id);

    this._feathersService.calls$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        this.calls = res;
        this.isOnlineLesson = this.calls.find((element) => element?.room?._id === this.room?._id);
      });

    this._getRoomOwner();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isChatNameEllipsized = this.chatName.nativeElement.offsetWidth < this.chatName.nativeElement.scrollWidth;
    }, 0);
  }

  selectAction(type: any, room: any): void {
    switch (type) {
    case 'Увімкнути сповіщення': {
      this._feathersService.remove(FeathersHubService.Notification, room.muted)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe();
      break;
    }
    case 'Вимкнути сповіщення': {
      this._feathersService.create(FeathersHubService.Notification, this.isChannelsSelect ? {channel: room._id} : {room: room._id})
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe();
      break;
    }
    case 'Відкріпити': {
      this._feathersService.remove(FeathersHubService.Pinnes, room.pinned._id)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe();
      break;
    }
    case 'Закріпити': {
      this._feathersService.create(FeathersHubService.Pinnes, {'room': room._id})
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe();
      break;
    }
    case 'Видалити': {
      this.deleteModal.open();
      break;
    }
    case 'Покинути': {
      this._feathersService.remove(FeathersHubService.Join, room._id,{query: {targetType: 'room'}}).subscribe();
      this._feathersService.leave(FeathersHubService.Rooms, {room: room._id}).subscribe(() =>
        this.leaveRoom.emit(room._id)
      );
      this._feathersService.remove(FeathersHubService.Join, room._id,{query: {targetType: 'room'}}).subscribe();
      this._feathersService.leave(FeathersHubService.Rooms, {room: room._id}).subscribe();
    }
    }
  }

  rightClick(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    if (!this.isMobileDevice) {
      this.openContextMenu({left: event.clientX, top: event.clientY});
    }
  }

  press(event: any): void {
    if (this.isMobileDevice) {
      this.openContextMenu({left: event.center.x, top: event.center.y});
    }
  }

  openContextMenu(data: { left: number, top: number }): void {
    this.target.show(this.chat);
    this.target.show({left: data.left, top: data.top});
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getRoomName(): void {
    if (this.room.type === this.chatType.Private) {
      this.roomName = getPrivateRoomName(this.room, this.currentUser.id);

      return;
    }
    this.roomName = this.room?.class?.subgroup
      ? `${this.room.name} група ${this.room.class.subgroup}`
      : this.room.name;
  }

  deletedRoom(): void {
    this.leaveRoom.emit(null);
  }

  private _listenCallEnded(): void {
    merge(this._feathersService.listen(FeathersHubMethod.CallEnded), this._feathersService.listen(FeathersHubMethod.CallDeclined))
      .subscribe({
        next: res => {
          if (this.isOnlineLesson?._id === res.data._id) {
            this.isOnlineLesson = null;
          }
        }
      });
  }

  private _subscribeOnCreate(): void {
    this._feathersService.listen(FeathersHubMethod.notificationsMuteCreate)
      .subscribe(value => {
        if (this.room._id === value.data.channel) {
          this.room.muted = value.data._id;
        }

      });
  }

  private _subscribeOnRemove(): void {
    this._feathersService.listen(FeathersHubMethod.notificationsMuteRemoved)
      .subscribe(value => {
        if (this.room._id === value.data.channel) {
          this.room.muted = null;
        }
      });
  }

  private _getLastInteractionTime(): void {
    if (this.room.lastMsg) {
      this.lastInteractionTime = this._formatDate(this.room.lastMsg?.createdAt);

      return;
    }
    this.lastInteractionTime = this._formatDate(this.room.updatedAt);
  }

  private _getRoomOwner(): void {
    this.isUserRoomOwner = isUserRoomOwner(this.room, this.currentUser.id);
  }

  private _formatDate(value: Date): string {
    const d = new Date(this._dateTransformService.transformDate(value));
    const currentDay = new Date();
    const seconds = Math.round(Math.abs((currentDay.getTime() - d.getTime()) / 1000));
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));

    return formatDate(value, hours < 24 ? 'HH:mm' : 'dd.MM.yyyy', 'uk-UA');
  }
}
