import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChatType, FeathersHubMethod, FeathersHubService} from '@nit-core/global/domain/enums';
import {FeathersService, SchoolService, UserService} from '@nit-services';
import {Profile} from '@nit-models';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {LastMessage, Room} from '@nit-core/models/room';
import {ChatCall} from '@nit-core/models/chat-notification';
import {UntilDestroy} from '@ngneat/until-destroy';
import {PermissionService} from '@nit-core/permission/permission.service';
import {filter, switchMap} from 'rxjs/operators';
import {AuthService} from '@nit-auth';
import {ChatService} from './services/chat.service';
import {isUserRoomMember, sortRooms} from './utils/chat.utils';
import {ChatsClass} from '@nit-core/models/class';
import {ClassSelectorBarComponent} from './components/class-selector-bar/class-selector-bar.component';
import {Platform} from '@angular/cdk/platform';
import {CommonModule} from '@angular/common';
import {RoomSelectorBarComponent} from './components/room-selector-bar/room-selector-bar.component';
import {NoDataComponent} from '@nit-core/components/no-data/no-data.component';
import {BehaviorSubject, merge} from 'rxjs';
import {LoaderContentComponent} from '../../components/loader-content/loader-content.component';

@UntilDestroy({checkProperties: true})
@Component({
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
  standalone: true,
  imports: [CommonModule, ClassSelectorBarComponent, RoomSelectorBarComponent, RouterOutlet, NoDataComponent, LoaderContentComponent]
})
export class ChatComponent implements OnInit, OnDestroy {
  readonly chooseClassText: string = 'Оберіть клас, щоб побачити список чатів';
  @ViewChild('classSelectorBar') classSelectorBar: ClassSelectorBarComponent;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  classes: ChatsClass[] = [];
  rooms: Room[] = [];
  filteredRooms: Room[] = [];
  roomsWhereUserIsMember: Room[] = [];
  classId: string;
  roomId: string;
  currentUser: Profile;
  semester: number;
  schoolId: number;
  childId: string;
  isAdminOrTeacher: boolean;
  isAdmin: boolean = false;
  teacherOwnClasses: ChatsClass[];
  isSchoolSelect: boolean = false;
  isChannelsSelect: boolean = false;
  chatType: typeof ChatType = ChatType;
  isChildrenList: boolean = false;
  chooseRoomText: string = 'Оберіть користувача чи групу, щоб розпочати листування';
  isDesktop: boolean;
  link: string;
  childActualClass: ChatsClass;
  isAfterCall: boolean = false;
  isAdminChannelsList: boolean = false;
  channelsList: any[] = [];
  private readonly _calls: ChatCall[] = [];

  constructor(private readonly _feathersService: FeathersService,
    public router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _userService: UserService,
    private readonly _schoolService: SchoolService,
    private readonly _permissionService: PermissionService,
    private readonly _authService: AuthService,
    private readonly _chatService: ChatService,
    private readonly _platform: Platform,
  ) {
    this._getChannels();
    this._redirectLink();
    this.isChildrenList = this._route.snapshot.data.isChildrenList;
    this._userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
    this.childId = this._route.snapshot.paramMap.get('userId');
    this.schoolId = this.childId === this._authService.userId
      ? this._authService.getUserSchoolId()
      : this._authService.getUserSchoolId(this.childId);
    this.isAdmin = this._permissionService.hasPermissionByUser(this.currentUser.id, ['chat:admin']);
    this.isAdminOrTeacher = this._permissionService.hasPermissionByUser(this.currentUser.id, ['chat:teacher']) && this.isAdmin;
    this._collapseMenu();
    this._readMessages();
    this._listenCallEnded();
    this._listenToRoomPatch();
  }

  ngOnInit(): void {
    if (this.isDesktop) {
      this._collapseMenu();
      this._subscribeOnCreate();
      this._subscribeOnRemove();
      this._getOnlineLesson();
      this._listenToRoomLeave();
      this._listenToRoomPatch();
      this._getChosenRoom();
    }
  }

  ngOnDestroy(): void {
    const body = document.body.classList;
    body.remove('menu-collapsed');
  }

  handleRoomChange(roomId: string): void {
    if (roomId) {
      this.roomId = roomId;
      this.rooms.find(x => x._id === roomId).unread_v2 = 0;
      if (!this.isChannelsSelect) {
        this._chatService.rooms$.next(this.rooms);
      }
    } else {
      this.roomId = null;
    }
  }

  handleFilterRooms(roomFilter: string): void {
    switch (roomFilter) {
    case 'Особисті повідомлення':
      this.filteredRooms = this.rooms.filter(e => e.type === this.chatType.Private);
      break;
    case 'Групи':
      this.filteredRooms = this.rooms.filter(e =>
        e.type === this.chatType.Direct || e.type === this.chatType.Default
      );
      break;
    case 'Непрочитані':
      this.filteredRooms = this.rooms.filter(e => e.unread_v2 > 0);
      break;
    default:
      this.filteredRooms = this.rooms;
    }
  }

  takeRooms(classId: string, semester: number, isClick: boolean = false): void {
    this.loading$.next(true);
    if (isClick && this.classId === classId) return;
    this.isChannelsSelect = false;
    this.roomId = '';
    this.classId = classId;
    this.isSchoolSelect = this.classes?.find(element => element._id === classId)?.isSchool;
    if (this.classId) {
      if (this.classId !== 'channels') {
        this._chatService.getRooms(this.schoolId, classId, semester).subscribe((rooms) => {
          rooms.data.forEach(room => {
            if (!room.lastMsg) {
              room.lastMsg = new LastMessage({createdAt: new Date(room.updatedAt)});
            }
          });
          this._chatService.rooms$.next(sortRooms(rooms.data));
          this.roomsWhereUserIsMember = rooms.data.filter(room => isUserRoomMember(room, this.currentUser.id));
          this.rooms = this.filteredRooms = this._chatService.rooms$.getValue();
          this.loading$.next(false);
        });
        this.chooseRoomText = 'Оберіть користувача чи групу, щоб розпочати листування';
      } else {
        this._getChannels();
      }
    }
  }

  getClasses(semester: number): void {
    this.rooms = [];
    this.semester = semester;
    this._schoolService.getSchoolStatus(this.childId, this.schoolId)
      .pipe(
        filter(response => response.active && !this.childId),
        switchMap(() => this._feathersService.find(FeathersHubService.Classes, {semester: {$in: [0, semester]} , 'school': this.schoolId}))
      )
      .subscribe(res => {
        this.classes = res.data.naturalSort('name');
        if (this._userService.isChild$.getValue()) {
          this.childActualClass = res.data.find(x => x._id === this._userService.currentSchoolInfo$.getValue().classId);
          this.takeRooms(this.childActualClass._id, semester, false);
        } else {
          this.takeRooms('', semester, false);
        }
        this.filteredRooms = [];
        const filteredClassTeacherClasses = this.classes.filter(testedClass => {
          return this._userService.isClassTeacher$.getValue()?.classes.some(classOfTutor => {
            return classOfTutor.id === testedClass._id;
          });
        });

        if (filteredClassTeacherClasses.length) {
          this.teacherOwnClasses = filteredClassTeacherClasses.naturalSort('name');
        }
        this.loading$.next(false);
      });
    if (this.isChildrenList || this.isAfterCall || this.isChannelsSelect) {
      this.takeRooms(this.classId, semester, false);
    }
  }

  private _listenCallEnded(): void {
    merge(this._feathersService.listen(FeathersHubMethod.CallEnded), this._feathersService.listen(FeathersHubMethod.CallDeclined))
      .subscribe({
        next: res => {
          this.roomId = res.data.room;
          this.isAfterCall = true;
        }
      });
  }

  private _getChannels(): void {
    this._feathersService.find(FeathersHubService.Channels)
      .subscribe(res => {
        this.channelsList = res.data;
        this.channelsList?.forEach(channel => {
          channel.admins.forEach(admin => {
            if (admin._id === this.currentUser.id) {
              this.isAdminChannelsList = true;
              this.classId = 'channels';
            }
          });
        });
        if (this.classId === 'channels') {
          this.isChannelsSelect = true;
          this.chooseRoomText = 'Для перегляду повідомлень оберіть канал';
          this.rooms = this.filteredRooms = this.channelsList;
          this.loading$.next(false);
        }
      });
  }

  private _getOnlineLesson(): void {
    this._feathersService.find(FeathersHubService.Media, {'school': this.schoolId})
      .subscribe(res => {
        this._calls.push(...res.data);
        this._feathersService.calls$.next(this._calls);
      });
  }

  private _collapseMenu(): void {
    const body = document.body.classList;
    body.add('menu-collapsed');
  }

  private _subscribeOnRemove(): void {
    this._feathersService.listen(FeathersHubMethod.roomsRemove).subscribe(value => {
      const index = this.rooms.findIndex(room => room._id === value.data._id);
      this.rooms.splice(index, 1);
    });
  }

  private _subscribeOnCreate(): void {
    this._feathersService.listen(FeathersHubMethod.roomsCreated).subscribe(value => {
      const isRepetRoom = this.rooms.find(e => e._id === value.data._id);
      this.roomId = value.data._id;
      if (this.classId === value.data.class._id && !isRepetRoom) {
        value.data.lastMsg = new LastMessage({
          createdAt: new Date(value.data.updatedAt)
        });
        this.rooms.unshift(value.data);
      }
    });
  }

  private _readMessages(): void {
    this._feathersService.listen(FeathersHubMethod.messages_v2Read)
      .subscribe(res => {
        const messages = res?.data.viewed;
        messages.forEach(message => {
          if (message.room === this.roomId) {
            this.rooms.find(x => x._id === message.room).lastMsg.readBy = message?.readBy;
          }
        });
      });
  }

  private _listenToRoomLeave(): void {
    this._feathersService.listen(FeathersHubMethod.LeaveRoom).subscribe(({data}) => {
      if (data.room._id === this.roomId) {
        if (data.user._id === data.room.owner || data.user._id === this.currentUser.id) {
          this.filteredRooms = this.rooms = this.rooms.filter(room => room._id !== data?.room?._id);
          this.roomId = '';
        }
        if (this.filteredRooms?.length === 0) {
          this.classId = '';
          this.classSelectorBar.selectedClass = '';
        }
      }
    });
  }

  private _listenToRoomPatch(): void {
    this._feathersService.listen(FeathersHubMethod.roomsPatched).subscribe(({data}) => {
      if (this.rooms.findIndex(x => x._id === data._id) === -1) {
        data.lastMsg = new LastMessage({createdAt: new Date(data.updatedAt)});
        this.rooms.unshift(data);
      }
    });
  }

  private _redirectLink(): void {
    const isAndroid: boolean = this._platform.ANDROID;
    const isIOS: boolean = this._platform.IOS;
    if (isAndroid || isIOS) {
      this.isDesktop = false;
      this.link = isAndroid
        ? 'https://play.google.com/store/apps/details?id=com.lionwood.nit&hl=uk'
        : 'https://apps.apple.com/ua/app/%D0%BD%D1%96%D1%82/id1619975637';
    } else {
      this.isDesktop = true;
      this.link = '';
    }
  }

  private _getChosenRoom(): void {
    this._chatService.selectedRoom$.subscribe(id => {
      this.roomId = id;
    });
  }
}
