<div #chat
     class="chats"
     [class.notification]="true"
     [class.selected]="room._id === selectedRoomId && !router.url.includes('online-lesson')" (click)="selectedRoom.emit(room._id)" (contextmenu)="rightClick($event)" (press)="press($event)">
  <div class="circle-name-container">
    <div *ngIf="isOnlineLesson && room.type === 'default' && !isSchool" class="box lesson-icon">УРОК</div>
    <div *ngIf="isOnlineLesson && (room.type !== 'default' || isSchool)" class="box lesson-icon">ДЗВІНОК</div>
    <div class="circle-name box" [ngStyle]="{'background': (room.type === 'private' ? '#F3B5B4' : '#A4DAEF')}">
      {{(roomName | roomName : room?.type === 'private')}}
    </div>
  </div>
  <div class="chat-info">

    <div class="chat-info-first-line">
      <div class="tob-text">
        <img *ngIf="room.pinned?._id" class="pinned" src="./assets/icons/chats/pin.svg" alt="pined">
        <span #chatName
              NitTooltip
              class="chat-name"
              [topText]="isChatNameEllipsized ? roomName : ''"
              [horizontalPosition]="'left'"
              [class.large]="(room.muted || room.pinned?._id || room.lastMsg?.user?._id === currentUser.id) && lastInteractionTime.length > 5"
              [class.middle]="middleNameSize"
              [class.small]="room.muted && room.pinned?._id && room.lastMsg?.user?._id === currentUser.id"
              [class.long-time]="lastInteractionTime.length > 5">
        {{ roomName }}
      </span>
      </div>
      <div class="right-column">
        <div class="notif-block">
          <img *ngIf="room.muted" src="./assets/icons/chats/muted.svg" alt="muted">
          <img *ngIf="room.lastMsg?.user?._id === currentUser.id && !room.lastMsg?.readBy?.length"
               src="./assets/icons/chats/unread.svg"
               alt="unread">
          <img *ngIf="room.lastMsg?.user?._id === currentUser.id && room.lastMsg?.readBy?.length"
               src="./assets/icons/chats/read.svg"
               alt="read">
        </div>
        <div class="time">{{ (room.lastMsg?.createdAt ? room.lastMsg?.createdAt : room?.updatedAt) | timeView }}</div>
      </div>
    </div>

    <div class="chat-info-second-line">
      <div class="msg-block">
        <span *ngIf="room?.lastMsg?.text; else file"
              class="last-msg"[class.has-unread-msg]="room.unread_v2 > 0">{{room?.lastMsg?.text | trimTags}}</span>
              <ng-template #file>
                <span *ngIf="room?.lastMsg?.files?.length" class="last-msg">
                  Файл
                </span>
              </ng-template>
        <span *ngIf="!room?.lastMsg?._id" class="last-msg">{{isChannelsSelect ? 'Канал' : 'Чат'}} щойно створено</span>
        <div *ngIf="room.unread_v2 > 0 && !isChannelsSelect" class="unread-msg">{{room.unread_v2}}</div>
      </div>
    </div>

    <kendo-contextmenu #target (select)="selectAction($event.item.text, room)">
      <kendo-menu-item *ngIf="room.muted && (isUserRoomMember || isChannelsSelect)" cssClass="menu-item unmute" text="Увімкнути сповіщення" />
      <kendo-menu-item *ngIf="!room.muted && (isUserRoomMember || isChannelsSelect)" cssClass="menu-item mute" text="Вимкнути сповіщення" />
      <kendo-menu-item *ngIf="room.pinned?._id && !isChannelsSelect" cssClass="menu-item unpin" text="Відкріпити" />
      <kendo-menu-item *ngIf="!room.pinned?._id && !isChannelsSelect" cssClass="menu-item pin no-overflow" text="Закріпити" />
      <kendo-menu-item *ngIf="canLeaveChat"
                       cssClass="menu-item remove"
                       text="Покинути" />
      <kendo-menu-item *ngIf="room.type !== chatType.Default && isUserRoomOwner && !isChildrenList && !isChannelsSelect"
                       cssClass="menu-item remove"
                       text="Видалити" />
    </kendo-contextmenu>
  </div>
</div>
<nit-delete-room-modal #deleteModal [room]='room' (submitted)="deletedRoom()" />
