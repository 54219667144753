<div *ngIf="isDesktop else mobileRedirect" class="chat">
  <nit-class-selector-bar #classSelectorBar
                          [classes]="classes"
                          [userId]="currentUser?.id"
                          [isAdminOrTeacher]="isAdminOrTeacher"
                          [teacherOwnClasses]="teacherOwnClasses"
                          [isChildrenList]="isChildrenList"
                          [selectedClass]="classId"
                          [isAdminChannelsList]="isAdminChannelsList"
                          [isPersonWithChannels]="!!channelsList.length"
                          [childActualClass]="childActualClass"
                          (classSelected)="takeRooms($event, semester, true)"
                          (selectedSemester)="getClasses($event)" />

  <nit-room-selector-bar *ngIf="classId"
                         [rooms]="filteredRooms"
                         [roomsWhereUserIsMember]="roomsWhereUserIsMember"
                         [currentUser]="currentUser"
                         [selectedClass]="classId"
                         [isAdmin]="isAdmin"
                         [isSchool]="isSchoolSelect"
                         [isChannelsSelect]="isChannelsSelect"
                         [schoolId]="schoolId"
                         [semester]="semester"
                         [selectedRoomId]="roomId"
                         (filterRooms)="handleFilterRooms($event)"
                         (roomChanged)="handleRoomChange($event)" />

  <ng-container *ngIf="!(loading$ | async); else loader">
    <div class="dialog-container">
      <ng-container *ngIf="(classId && roomId) || router.url.includes('create') || router.url.includes('saved') || router.url.includes('online-lesson')">
        <router-outlet />
      </ng-container>

      <nit-no-data *ngIf="(!classId || !roomId) && !router.url.includes('create') && !router.url.includes('saved') && !router.url.includes('online-lesson')"
                   [hasData]="false"
                   [text]="!classId ? chooseClassText : chooseRoomText" />
    </div>
  </ng-container>

  <ng-template #loader>
    <nit-loader-content />
  </ng-template>
</div>
<ng-template #mobileRedirect>
  <div class="redirect-mobile">
    <nit-no-data [text]="'Функціонал Комунікація доступний в додатку НІТ'" />
    <a [href]="link">ВІДКРИТИ</a>
  </div>
</ng-template>
