import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, QueryList,
  SimpleChanges, ViewChildren
} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {Profile} from '@nit-models';
import {FeathersService} from '@nit-services';
import {FeathersHubMethod, FeathersHubService} from '@nit-core/global/domain/enums';
import {Subscription, merge} from 'rxjs';
import {ChatCall} from '@nit-core/models/chat-notification';
import {LastMessage, Room, SearchRoom} from '@nit-core/models/room';
import {UntilDestroy} from '@ngneat/until-destroy';
import {isUserRoomMember, sortRooms} from '../../utils/chat.utils';
import {ChatListItemComponent} from '../chat-list-item/chat-list-item.component';
import {FilterChatsComponent} from '../filter-chats/filter-chats.component';
import {ChatSearchListComponent} from '../chat-search-list/chat-search-list.component';
import {CommonModule} from '@angular/common';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'nit-room-selector-bar',
  templateUrl: './room-selector-bar.component.html',
  styleUrl: './room-selector-bar.component.scss',
  standalone: true,
  imports: [FilterChatsComponent, CommonModule, RouterLink, ChatListItemComponent, ChatSearchListComponent]
})

export class RoomSelectorBarComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChildren('chatItem') chatItem: QueryList<ChatListItemComponent>;

  @Input() rooms: Room[];
  @Input() selectedClass: string;
  @Input() semester: number;
  @Input() schoolId: number;
  @Input() currentUser: Profile;
  @Input() isSchool: boolean;
  @Input() isAdmin: boolean;
  @Input() selectedRoomId: string;
  @Input() roomsWhereUserIsMember: Room[];
  @Input() isChannelsSelect: boolean;

  @Output() roomChanged: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Output() filterRooms: EventEmitter<string> = new EventEmitter<string>();
  searchData: SearchRoom | null = null;
  calls: ChatCall[] = [];
  ringCalls: ChatCall[] = [];
  private readonly _subscriptions: Subscription = new Subscription();
  private _currentRooms: Room[];
  private readonly _childId: string;


  constructor(
    private readonly _feathersService: FeathersService,
    private readonly _route: ActivatedRoute,
    public router: Router
  ) {
    this._childId = this._route.snapshot.paramMap.get('userId');
    this._listenCall();
  }

  ngOnInit(): void {
    this._subscribeOnRemove();
    this._subscribeOnCreate();
    this._listenToLastMessage();
    this._onChangeRoom();
    this._listenMessage();
    this._currentRooms = this.rooms;
    const callsList = this._feathersService.calls$.subscribe((res) =>{this.calls = res;});
    this._feathersService.ringCalls$.subscribe((res) =>{this.ringCalls = res;});
    this._subscriptions.add(callsList);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.searchData = null;
    if (changes.selectedRoomId?.currentValue) {
      this.selectedRoomId = changes.selectedRoomId?.currentValue;

      const currentRoom = this.rooms.find(room => room._id === this.selectedRoomId);
      if (isUserRoomMember(currentRoom, this.currentUser.id)) {
        this.roomsWhereUserIsMember.push(currentRoom);
      }
    }
    this.rooms = sortRooms(this.rooms);
  }

  ngOnDestroy(): void {
    if (this.selectedRoomId) {
      const query = {
        query: {
          targetType: this.isChannelsSelect ? 'channel' : 'room'
        }
      };
      this._feathersService.remove(FeathersHubService.Join, this.selectedRoomId,query).subscribe();
    }
  }

  selectRoom(id: string): void {
    if (this.selectedRoomId !== id) {
      if (this.selectedRoomId) {
        const query = {
          query: {
            targetType: this.isChannelsSelect ? 'channel' : 'room'
          }
        };
        this._feathersService.remove(FeathersHubService.Join,id, query).subscribe();
      }
      this._feathersService.create(FeathersHubService.Join, {targetId: id, targetType: this.isChannelsSelect ? 'channel' : 'room'})?.subscribe();
      this.selectedRoomId = id;
      this.roomChanged.emit(this.selectedRoomId);
    }

    if (this._childId) {
      this.router.navigate([`/children/${this._childId}/chat`, this.selectedClass, id, this.schoolId], {queryParams: {childId: this._childId}});
    } else {
      this.router.navigate(['/chat', this.selectedClass, this.selectedRoomId, this.schoolId]);
    }
  }

  sortRooms(filter: string): void {
    this.filterRooms.emit(filter);
  }

  savedChat(): void {
    this.selectedRoomId = 'create';
    this.router.navigate(this._childId
      ? [`/children/${this._childId}/chat`, this.selectedClass, 'saved', this.schoolId]
      : ['/chat', this.selectedClass,'saved', this.schoolId]);
  }

  searchRoom(search: SearchRoom | null): void {
    this.searchData = search;
  }

  leaveRoom(roomId: string): void {
    if (roomId) {
      const index = this.rooms.findIndex(x => x._id === roomId);
      if (index !== -1) {
        this.rooms.splice(index, 1);
      }
    } else {
      this.router.navigate(['/chat']);
      this.selectedRoomId = null;
      this.roomChanged.emit(null);
    }
  }

  private _listenCall(): void {
    const listenCall = merge(this._feathersService.listen(FeathersHubMethod.CallStarted), this._feathersService.listen(FeathersHubMethod.CallEnded), this._feathersService.listen(FeathersHubMethod.CallDeclined))
      .subscribe({
        next: res => {
          if (res.method === FeathersHubMethod.CallStarted) {
            const userList = res.data.users.map(x => x._id);
            if (userList.includes(this.currentUser.id)) {
              if (!this.calls.includes(res.data)) {
                this._attachCall(res.data);
              }
              if (!this.ringCalls.includes(res.data)) {
                this.ringCalls.push(res.data);
                this._attachRingCalls(this.ringCalls);
              }
            }
          } else if (res.method === FeathersHubMethod.CallEnded || res.method === FeathersHubMethod.CallDeclined) {
            const lesson = this.calls.find(x => x._id === res.data._id);
            this.ringCalls.splice(this.calls.findIndex(() => res.data), 1);
            this._attachRingCalls(this.ringCalls);
            if (lesson) {
              this.calls.splice(this.calls.findIndex(() => lesson), 1);
              this._feathersService.calls$.next(this.calls);
            }
          }
        }
      });
    this._subscriptions.add(listenCall);
  }

  private _attachCall(call: ChatCall): void {
    this.calls.push(call);
    this._feathersService.calls$.next(this.calls);
    this.ringCalls.splice(this.calls.findIndex(() => call), 1);
    this._feathersService.ringCalls$.next(this.ringCalls);
  }

  private _attachRingCalls(ringCalls: ChatCall[]): void {
    this._feathersService.ringCalls$.next(ringCalls);
  }

  private _subscribeOnRemove(): void {
    this._feathersService.listen(FeathersHubMethod.notificationsMuteRemoved)
      .subscribe(value => {
        this.rooms.map(room => {
          if (room._id === value.data.room) {
            return room.muted = null;
          }
        });
      });

    this._feathersService.listen(FeathersHubMethod.pinnesRemove).subscribe(value => {
      this.rooms.map(room => {
        if (room._id === value.data.room) {
          return room.pinned = null;
        }
      });

      this.rooms = sortRooms(this.rooms);
    });
  }

  private _subscribeOnCreate(): void {
    this._feathersService.listen(FeathersHubMethod.notificationsMuteCreate)
      .subscribe(value => {
        this.rooms.map(room => {
          if (room._id === value.data.room) {
            return room.muted = value.data._id;
          }
        });
      });

    this._feathersService.listen(FeathersHubMethod.pinnesCreate)
      .subscribe(value => {
        this.rooms.map(room => {
          if (room._id === value.data.room) { return room.pinned = value.data; }
        });

        this.rooms = sortRooms(this.rooms);
      });
  }

  private _listenToLastMessage(): void {
    merge(this._feathersService.listen(FeathersHubMethod.messages_v2Patched),this._feathersService.listen(FeathersHubMethod.MessageReceived_v2), this._feathersService.listen(FeathersHubMethod.messages_v2Created), this._feathersService.listen(FeathersHubMethod.channelsLastMessage), this._feathersService.listen(FeathersHubMethod.roomsLastMessage))
      .subscribe({
        next: res => {
          if (res.data.room?.class?._id === this.selectedClass || this.isChannelsSelect) {
            const index = this.rooms.findIndex(room => room._id === (this.isChannelsSelect ? res.data?.channel || res.data.targetId : res.data.room?._id));
            if (index !== -1) {
              if (res.data.message) {
                this.rooms[index].lastMsg = res.data.message;
                this.rooms[index].lastMsg.user = res.data?.user;
              }
              else if (res.method === FeathersHubMethod.messages_v2Created || res.method === FeathersHubMethod.messages_v2Patched) {
                this.rooms[index].lastMsg = res.data;
              }
              this.rooms = sortRooms(this.rooms);
            }
          }
        }
      });
  }

  private _onChangeRoom(): void {
    this._feathersService.listen(FeathersHubMethod.roomsPatched).subscribe(({data}) => {
      if (this.selectedClass === data.class._id) {
        const index = this.rooms.findIndex(room => room._id === data._id);
        if (index === -1) {
          data.lastMsg = new LastMessage({createdAt: new Date(data.updatedAt)});
          this.rooms.unshift(data);
        } else {
          this.chatItem.toArray()[index].roomName = data.name;
          this.rooms[index].name = data.name;
          this.roomsWhereUserIsMember = [...this.roomsWhereUserIsMember, data];
        }
      }
    });
  }

  private _listenMessage(): void {
    this._feathersService.listen(FeathersHubMethod.MessageReceived_v2)
      .subscribe(res => {
        const {targetId, message, unread, type} = res.data;
        if (type === 'room' || type === 'channel') {
          if (this.selectedRoomId !== targetId) {
            const index = this.rooms.findIndex(x => x._id === targetId);
            if (index !== -1 && message?.user._id !== this.currentUser.id) {
              this.rooms[index].unread_v2 = unread;
            }
          }
        }
      });
  }
}
